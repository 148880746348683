// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abc-des-inspirations-js": () => import("./../../../src/pages/abc-des-inspirations.js" /* webpackChunkName: "component---src-pages-abc-des-inspirations-js" */),
  "component---src-pages-ajoutez-une-inspiration-js": () => import("./../../../src/pages/ajoutez-une-inspiration.js" /* webpackChunkName: "component---src-pages-ajoutez-une-inspiration-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

